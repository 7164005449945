<template>
  <el-dialog title="银行卡身份验证查询结果" width="500" :visible.sync="visible" :fullscreen="full" :close-on-click-modal="false" :close-on-press-escape="false" custom-class="v-dialog v-dialog--highLight risk-dialog" @open="computeHeight">
    <div>
      <tool :full-screen.sync="full" @download="download('银行卡身份验证查询结果')"></tool>
      <h2 class="risk-dialogTitle">银行卡身份验证查询结果</h2>
      <table class="risk-dialogTable">
        <colgroup>
          <col width="150"/>
          <col width="250"/>
        </colgroup>
        <tbody>
          <tr>
            <td>姓名</td>
            <td>{{v.result.data.name}}</td>
          </tr>
          <tr>
            <td>身份证号</td>
            <td>{{v.result.data.identityNo}}</td>
          </tr>
          <tr>
            <td>银行卡号</td>
            <td>{{v.result.data.bankCardNo}}</td>
          </tr>
          <tr>
            <td>数据渠道</td>
            <td>{{v.result.data.channel}}</td>
          </tr>
          <tr>
            <td>手机号</td>
            <td>{{v.result.data.mobile}}</td>
          </tr>
          <tr>
            <td>业务结果状态码</td>
            <td>{{v.result.data.resultCode}}</td>
          </tr>
          <tr>
            <td>银行名称</td>
            <td>{{v.result.data.bankCardBin.bankName}}</td>
          </tr>
          <tr>
            <td>卡名称</td>
            <td>{{v.result.data.bankCardBin.cardName}}</td>
          </tr>
          <tr>
            <td>银行卡类型</td>
            <td>{{v.result.data.bankCardBin.cardTy}}</td>
          </tr>
          <tr>
            <td>银行ID</td>
            <td>{{v.result.data.bankCardBin.bankId}}</td>
          </tr>
          <tr>
            <td>卡binID</td>
            <td>{{v.result.data.bankCardBin.binStat}}</td>
          </tr>
          <tr>
            <td>附加信息</td>
            <td>{{v.result.data.remark}}</td>
          </tr>
          <tr>
            <td>业务结果</td>
            <td>{{v.result.data.resultMsg}}</td>
          </tr>
          <tr>
            <td>查询时间</td>
            <td>{{miment().format('YYYY年MM月DD日 hh点mm分')}}</td>
          </tr>
        </tbody>
      </table>
    </div>

  </el-dialog>
</template>

<script>
import mixin from './mixin'
export default {
  data() {
    return {
      visible: false,
      v: null
    }
  },
  mixins: [mixin],
  methods: {
    open(datas) {
      this.visible = true
      this.v = Object.assign(this.v, datas)
    }
  },
  created() {
    // 初始化数据
    this.v = JSON.parse("{\"code\":\"10000\",\"charge\":false,\"msg\":\"查询成功\",\"result\":{\"msg\":\"成功\",\"code\":\"0000\",\"data\":{\"identityNo\":\"\",\"bankCardNo\":\"\",\"name\":\"\",\"channel\":\"\",\"mobile\":\"\",\"resultCode\":\"\",\"bankCardBin\":{\"bankId\":\"\",\"cardName\":\"\",\"cardTy\":\"\",\"bankName\":\"\",\"id\":\"\",\"binStat\":\"\"},\"remark\":\"\",\"resultMsg\":\"\"}}}")
  }
}
</script>
